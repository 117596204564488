import { FC, useEffect, useMemo, useState } from "react";
import billingDataRouteRequestsFactory from "./factory/billing-data-route-requests.factory";
import billingMapRoutesFactory from "./factory/billing-map-routes.factory";
import BillingsNode from "../../common/types/billings-node";
import billingRecalculateRequestFactory from "./factory/billing-recalculate-request.factory";
import billingDataFactory from "./factory/billing-data.factory";
import BillingDetailsComponent from "./billing-details/billing-details.component";
import ContractDetailsComponent from "./contract-details/contract-details.component";
import billingSaveRequestFactory from "./factory/billing-save-request.factory";
import BonusType from "./types/bonus-type";
import PenaltyType from "./types/penalty-type";
import RelatedBillingsCargoTaxiComponent from "../common/related-billings/cargo-taxi/related-billings-cargo-taxi.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import MapComponent from "../../../../common/components/map/map.component";
import MapMarker from "../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../common/components/map/types/map-route";
import SearchRoadRoutingResponse from "../../../../common/utils/search-road-route/search-road-routing.response";
import BillingFormData, { BillingSummaryData } from "./types/billing-form.data";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingTaxiTaxiApiService from "./api/billings-taxi-taxi-api.service";
import billingsApiService from "./api/billing-api.service";
import BillingDataResponse from "./api/billing-data.response";
import BillingGpsResponse from "./api/billing-gps-data.response";
import BillingSaveResponse from "./api/billing-save.response";
import { useNavigate, useParams } from "react-router-dom";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import notificationService from "../../../../common/utils/notification/notification.service";
import settlementRoutesHelper from "../../../settlement/common/routes/settlement-routes.helper";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import BillingTaxiTaxiEditRouteParams from "../../common/routes/types/billing-taxi-taxi-edit-route-params";
import RelatedBillingsTaxiDriverComponent from "../common/related-billings/taxi-driver/related-billings-taxi-driver.component";
import RelatedBillingsTaxiTaxiComponent from "../common/related-billings/taxi-taxi/related-billings-taxi-taxi.component";
import BillingsSummaryConfirmationComponent from "./billings-summary-confirmation.component";
import BillingStatus from "../../types/billing-status";
import BillingRouteDetailsFormDataValidationResult, {
  NodeValidationResult,
} from "../../common/route-details/types/billings-route-details-form-data-validation-result";
import billingRouteDetailsHelper from "../../common/route-details/common/billings-route-details.helper";
import billingRouteDetailsDataValidationService from "../../common/route-details/common/billing-route-details-form-data-validation.service";
import BillingsRouteDetailsFormComponent from "../../common/route-details/billings-route-details-form.component";
import MessengerComponent from "../../../../common/components/messenger/messenger.component";
import MessengerBillingType from "../../../../common/components/messenger/types/messenger-billing-type";
import billingsTaxiTaxiHelper from "./billings-taxi-taxi.helper";
import billingsMapMarkersFactory from "../../common/map-markers/billings-map-markers.factory";
import OrderDetailsHistoryComponent from "../../../order/details/history/order-details-history.component";
import BillingRouteDetailsComponent from "./billing-route-details/billing-route-details.component";
import BillingsToolsComponent from "../../common/tools/billings-tools.component";
import TabsData from "../../../../common/components/tabs/common/types/tabs-data";
import BillingsTaxiTaxiContributionBonus, {
  BillingsTaxiTaxiContributionBonusFavorableDistance,
  BillingsTaxiTaxiContributionBonusOther,
  BillingsTaxiTaxiContributionBonusType,
} from "../common/contributions/bonus/types/billings-taxi-taxi-contributions-bonus";
import BillingsTaxiTaxiContributionPenalty, {
  BillingsTaxiTaxiContributionPenaltyType,
} from "../common/contributions/penalty/types/billings-taxi-taxi-contributions-penalty";
import BillingContributionType from "./types/billing-contribution-type";
import billingsTaxiTaxiContributionsValidationService from "../common/contributions/billings-taxi-taxi-contributions-validation.service";
import BillingContributionsValidationResult from "./types/billing-contributions-validation-result";
import TabsComponent from "../../../../common/components/tabs/tabs.component";
import useApiCall from "../../common/hooks/use-api-call";

type BillingsTaxiTaxiEditProps = {};

const BillingsTaxiTaxiEditComponent: FC<BillingsTaxiTaxiEditProps> = () => {
  const { billingUuid } = useParams<BillingTaxiTaxiEditRouteParams>();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [isBillingSavePending, setIsBillingSavePending] = useState(false);

  const [routeId, setRouteId] = useState("");

  const [billingData, setBillingData] = useState<BillingFormData>();
  const [billingFormData, setBillingFormData] = useState<BillingFormData>();
  const [billingSummaryData, setBillingSummaryData] = useState<BillingSummaryData>();

  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>();
  const [plannedMapRoute, setPlannedMapRoute] = useState<MapRoute | null>(null);
  const [completedMapRoute, setCompletedMapRoute] = useState<MapRoute | null>(
    null
  );
  const [isSummaryConfirmationVisible, setIsSummaryConfirmationVisible] =
    useState(false);
  const [planEntryUuid, setPlanEntryUuid] = useState("");

  const [shouldRetrieveRouteData, setShouldRetrieveRouteData] = useState(false);

  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);
  const [formValidationResults, setFormValidationResults] =
    useState<BillingRouteDetailsFormDataValidationResult>({
      discountValue: formValidationService.defaultValidationResult,
      nodeValidations: [],
      penaltyValue: formValidationService.defaultValidationResult,
    });
  const [contributionsValidationResults, setContributionsValidationResults] =
    useState<BillingContributionsValidationResult>({ bonus: [], penalty: [] });

  const [shouldShowOrderHistory, setShouldShowOrderHistory] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setIsBillingDataLoading(true);
    billingTaxiTaxiApiService
      .fetchBillingData(billingUuid!)
      .then(handleBillingDataResponse)
      .finally(() => {
        setIsBillingDataLoading(false);
      });
  }, [billingUuid]);

  const navigate = useNavigate();

  const documentTitle = appTranslationsHelper
    .getDocumentTitleTranslations()
    .billingsTaxiWithTaxiEdit.replace("#{routeId}", routeId);

  useDocumentTitle(documentTitle);

  const onModalClose = () => {
    setIsSummaryConfirmationVisible(false);
  };

  const openModal = () => {
    setIsSummaryConfirmationVisible(true);
  };

  useEffect(() => {
    const breadcrumbs = billingBreadcrumbsHelper.getTaxiWithTaxiEditBreadcrumbs(
      {
        billingUuid: billingUuid!,
        routeId,
      }
    );

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, routeId]);

  useEffect(() => {
    if (!planEntryUuid) {
      return;
    }

    billingsApiService.fetchGpsData(planEntryUuid).then(handleGpsDataResponse);
  }, [planEntryUuid]);

  useEffect(() => {
    if (!billingData || !shouldRetrieveRouteData) {
      return;
    }

    const mapMarkers = billingsMapMarkersFactory.createMapMarkers(
      billingData.billingNodes
    );

    const routeRequests =
      billingDataRouteRequestsFactory.createBillingDataRouteRequests(
        billingData.billingNodes
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    routeRequests.forEach((routeRequest) => {
      const fetch = billingTaxiTaxiApiService.fetchRoute(routeRequest);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response, index) => {
        const mapRoute = response.routes[0]
          ? billingMapRoutesFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRouteWaypoints: MapRoute["waypoints"] = [];

      mapRouteWaypointGroups.forEach((waypoint) => {
        newMapRouteWaypoints.push(...waypoint);
      });

      const newMapRoute: MapRoute = {
        waypoints: newMapRouteWaypoints,
        options: { color: "red" },
      };

      setPlannedMapRoute(newMapRoute);
      setMapMarkers(mapMarkers);
    });
  }, [shouldRetrieveRouteData]);

  const onCopyFromPlannedDistance = () => {
    const newBillingNodes = billingFormData?.billingNodes.map((node) => {
      node.distance = node.plannedDistance;

      return node;
    });

    const newBillingFormData: BillingFormData = {
      ...billingFormData!,
      billingNodes: newBillingNodes!,
    };

    setBillingFormData(newBillingFormData);

    const result = billingRouteDetailsHelper.validateAllFieldsByType(
      formValidationResults,
      newBillingFormData.billingNodes,
      "distance"
    );

    nodeValidationResults(result.nodeResult!);

    if (result.isAllFormValid) recalculateBilling(newBillingFormData);
  };

  const recalculateBillingFunction = (formData: BillingFormData) => {
    const recalculateRequest =
      billingRecalculateRequestFactory.createRecalculateRequest(formData, billingSummaryData!);

    billingTaxiTaxiApiService
      .recalculateBilling(billingSummaryData!.forwardingId, recalculateRequest)
      .then(handleRecalculateResponse);
  };

  const recalculateBilling = useApiCall(recalculateBillingFunction, 50);
  const deleteContributionRecalculateBilling = useApiCall(
    recalculateBillingFunction,
    250
  );

  const handleBillingDataResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const handleGpsDataResponse = (response: BillingGpsResponse) => {
    if (response.status === 200) {
      onGpsDataFetchSuccess(response);
    }
  };

  const handleRecalculateResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onRecalculateSuccess(response);
    }
  };

  const navigateToListing = () => {
    navigate(settlementRoutesHelper.getTaxiListingOfSettledRoutesRoute());
  };

  const handleBillingSaveResponse = (response: BillingSaveResponse) => {
    if (response.status === 200) {
      notificationService.success(translations.successNotificationText);
      navigateToListing();

      return;
    }
    notificationService.error(translations.failureNotificationText);
  };

  const onBillingDataFetchSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);
    const billingSummaryData = billingDataFactory.createBillingSummaryData(response.data);

    setBillingData(billingData);
    setBillingFormData(billingData);
    setBillingSummaryData(billingSummaryData);
    setPlanEntryUuid(response.data.plan_entry_id);
    setRouteId(String(billingSummaryData.internalOrderId));
    setShouldRetrieveRouteData(true);

    setFormValidationResults((current) => ({
      discountValue: current.discountValue,
      nodeValidations: billingData.billingNodes.map((x) => {
        return {
          haltingTime: formValidationService.defaultValidationResult,
          distance: formValidationService.defaultValidationResult,
          highwayCharge: formValidationService.defaultValidationResult,
          position: x.position,
        };
      }),
      penaltyValue: current.penaltyValue,
    }));

    const contributionsValidationResult: BillingContributionsValidationResult =
      {
        bonus: billingData.billingContributions.bonus.map(
          (contribution, index) => ({
            position: index,
            type: formValidationService.defaultValidationResult,
            distance: formValidationService.defaultValidationResult,
            rate: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            comment: formValidationService.defaultValidationResult,
          })
        ),
        penalty: billingData.billingContributions.penalty.map(
          (contribution, index) => ({
            position: index,
            type: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            comment: formValidationService.defaultValidationResult,
          })
        ),
      };

    setContributionsValidationResults(contributionsValidationResult);
  };

  const onGpsDataFetchSuccess = (response: BillingGpsResponse) => {
    const gpsData: MapRoute = {
      waypoints: response.data.map((x) => {
        return { latitude: x.lat, longitude: x.lon };
      }),
      options: { color: "blue" },
    };

    setCompletedMapRoute(gpsData);
  };

  const onBillingDataSave = () => {
    const areNodesValid =
      billingFormData?.billingNodes
        .map((node) => {
          return billingRouteDetailsHelper.haltingTimeValidation(
            formValidationResults,
            node.haltingTime,
            node.position
          );
        })
        .every((x) => x) ?? false;

    const areContributionsValid = validateContributions();

    const isFormValid =
      areContributionsValid && areNodesValid && validatePenalty();

    if (!isFormValid) {
      notificationService.error(translations.failureValidationNotificationText);
      onModalClose();
      return;
    }

    if (billingFormData && billingSummaryData) {
      setIsBillingSavePending(true);

      const data =
        billingSaveRequestFactory.createSaveBillingRequest(billingFormData, billingSummaryData);

      billingTaxiTaxiApiService
        .saveBillingData(billingUuid!, data)
        .then(handleBillingSaveResponse)
        .finally(() => setIsBillingSavePending(false));
    }
  };

  const onPenaltyBlur = () => {
    const shouldRecalculate = validatePenalty();

    if (shouldRecalculate) {
      recalculateBilling(billingFormData!);
    }
  };

  const onBonusBlur = () => {
    const shouldRecalculate = validateBonus();

    if (shouldRecalculate) recalculateBilling(billingFormData!);
  };

  const validatePenalty = () => {
    const maximumPenaltyValue = billingSummaryData?.allContributionsAmount ?? 0;

    const validationResult =
      billingRouteDetailsDataValidationService.validatePenalty(
        billingSummaryData?.sumOfPenalties ?? 0,
        maximumPenaltyValue
      );

    setFormValidationResults((curr) => ({
      ...curr,
      penaltyValue: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateBonus = () => {
    const validationResult =
      billingRouteDetailsDataValidationService.validateBonus(
        billingSummaryData?.sumOfBonuses!
      );

    setFormValidationResults((curr) => ({
      ...curr,
      discountValue: validationResult,
    }));

    return validationResult.isValid;
  };

  const validationOnBlur = (position: number, fieldName: string) => {
    type Node = keyof typeof node;

    const node = billingFormData?.billingNodes.find(
      (x) => x.position === position
    );

    const fieldToValidate = node && node[fieldName as Node];

    const results = billingRouteDetailsHelper.validationOnBlur(
      fieldToValidate,
      formValidationResults,
      position,
      fieldName
    );

    nodeValidationResults(results.nodeResult!);

    if (results.isAllFormValid) recalculateBilling(billingFormData!);
  };

  const nodeValidationResults = (node: NodeValidationResult[]) => {
    setFormValidationResults((curr) => ({
      ...curr,
      nodeValidations: node,
    }));
  };

  const onBonusChanged = (bonusValue: number) => {
    setBillingFormData((current) => ({
      ...current!,
      bonus: bonusValue,
    }));
  };

  const onBonusTypeChanged = (bonusType: BonusType) => {
    setBillingFormData((current) => ({
      ...current!,
      bonusType: bonusType,
    }));
  };

  const onPenaltyChanged = (penaltyValue: number) => {
    setBillingFormData((current) => ({
      ...current!,
      penalty: penaltyValue,
    }));
  };

  const onPenaltyTypeChanged = (penaltyType: PenaltyType) => {
    setBillingFormData((current) => ({
      ...current!,
      penaltyType: penaltyType,
    }));
  };

  const onBillingNodeChanged = (node: BillingsNode) => {
    const nodes = billingFormData?.billingNodes.filter(
      (x) => x.position !== node.position
    )!;

    nodes.push(node);

    const sortedArray = nodes.sort((a, b) =>
      a.position < b.position ? -1 : 1
    );

    setBillingFormData((current) => ({
      ...current!,
      billingNodes: sortedArray,
    }));
  };

  const onInputBlur = () => {
    recalculateBilling(billingFormData!);
  };

  const onRecalculateSuccess = (response: BillingDataResponse) => {
    const billingSummaryData = billingDataFactory.createBillingSummaryData(response.data);
    setBillingSummaryData(billingSummaryData);
  };

  const mapRoutes: MapRoute[] = useMemo(() => {
    const finalMapRoutes: MapRoute[] = [];
    if (plannedMapRoute) {
      finalMapRoutes.push(plannedMapRoute);
    }
    if (completedMapRoute) {
      finalMapRoutes.push(completedMapRoute);
    }
    return finalMapRoutes;
  }, [plannedMapRoute, completedMapRoute]);

  const translations =
    billingsTranslationsHelper.getTaxiTaxiEditBillingsTranslations();

  const isSettleButtonVisible = billingSummaryData?.status !== BillingStatus.ACCEPTED;

  const messengerChannelsAvailability =
    billingsTaxiTaxiHelper.getMessengerChannelAvailability();

  const validateContributionType = (
    type:
      | BillingsTaxiTaxiContributionBonusType
      | BillingsTaxiTaxiContributionPenaltyType
      | null,
    position: number,
    contributionType: BillingContributionType
  ) => {
    const validationResult =
      billingsTaxiTaxiContributionsValidationService.validateType(type);

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(contributionType === BillingContributionType.BONUS && {
        bonus: contributionsValidationResults.bonus.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                type: validationResult,
              }
            : validation
        ),
      }),
      ...(contributionType === BillingContributionType.PENALTY && {
        penalty: contributionsValidationResults.penalty.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                type: validationResult,
              }
            : validation
        ),
      }),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const validateContributionDistance = (
    distance: number | null,
    position: number
  ) => {
    const validationResult =
      billingsTaxiTaxiContributionsValidationService.validateDistance(distance);

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      bonus: contributionsValidationResults.bonus.map((validation) =>
        validation.position === position
          ? {
              ...validation,
              distance: validationResult,
            }
          : validation
      ),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const validateContributionRate = (rate: number | null, position: number) => {
    const validationResult =
      billingsTaxiTaxiContributionsValidationService.validateRate(rate);

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      bonus: contributionsValidationResults.bonus.map((validation, index) =>
        validation.position === position
          ? {
              ...validation,
              rate: validationResult,
            }
          : validation
      ),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const validateContributionAmount = (
    amount: number | null,
    position: number,
    contributionType: BillingContributionType
  ) => {
    const validationResult =
      billingsTaxiTaxiContributionsValidationService.validateAmount(amount);

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(contributionType === BillingContributionType.BONUS && {
        bonus: contributionsValidationResults.bonus.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                amount: validationResult,
              }
            : validation
        ),
      }),
      ...(contributionType === BillingContributionType.PENALTY && {
        penalty: contributionsValidationResults.penalty.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                amount: validationResult,
              }
            : validation
        ),
      }),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const validateContributionComment = (
    comment: string | null,
    position: number,
    contributionType: BillingContributionType
  ) => {
    const validationResult =
      billingsTaxiTaxiContributionsValidationService.validateComment(
        comment ?? ""
      );

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(contributionType === BillingContributionType.BONUS && {
        bonus: contributionsValidationResults.bonus.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                comment: validationResult,
              }
            : validation
        ),
      }),
      ...(contributionType === BillingContributionType.PENALTY && {
        penalty: contributionsValidationResults.penalty.map(
          (validation, index) =>
            validation.position === position
              ? {
                  ...validation,
                  comment: validationResult,
                }
              : validation
        ),
      }),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const onContributionTypeChange = (
    position: number,
    type:
      | BillingsTaxiTaxiContributionBonusType
      | BillingsTaxiTaxiContributionPenaltyType,
    contributionType: BillingContributionType
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(contributionType === BillingContributionType.BONUS && {
          bonus: billingFormData!.billingContributions.bonus.map(
            (contribution, index) =>
              index === position
                ? type ===
                  BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE
                  ? ({
                      ...contribution,
                      type,
                      rate: billingSummaryData?.contractDetails?.distanceRate,
                    } as BillingsTaxiTaxiContributionBonusFavorableDistance)
                  : ({
                      ...contribution,
                      type,
                    } as BillingsTaxiTaxiContributionBonusOther)
                : contribution
          ),
        }),
        ...(contributionType === BillingContributionType.PENALTY && {
          penalty: billingFormData!.billingContributions.penalty.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    type,
                  } as BillingsTaxiTaxiContributionPenalty)
                : contribution
          ),
        }),
      },
    };

    setBillingFormData(formData);
    recalculateBilling(formData);
  };

  const onContributionDistanceChange = (
    position: number,
    distance: number | null
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        bonus: billingFormData!.billingContributions.bonus.map(
          (contribution, index) =>
            index === position
              ? ({
                  ...contribution,
                  distance,
                } as BillingsTaxiTaxiContributionBonus)
              : contribution
        ),
      },
    };

    setBillingFormData(formData);
  };

  const onContributionRateChange = (position: number, rate: number | null) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        bonus: billingFormData!.billingContributions.bonus.map(
          (contribution, index) =>
            index === position
              ? ({
                  ...contribution,
                  rate,
                } as BillingsTaxiTaxiContributionBonus)
              : contribution
        ),
      },
    };

    setBillingFormData(formData);
  };

  const onContributionAmountChange = (
    position: number,
    amount: number | null,
    contributionType: BillingContributionType
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(contributionType === BillingContributionType.BONUS && {
          bonus: billingFormData!.billingContributions.bonus.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    amount,
                  } as BillingsTaxiTaxiContributionBonus)
                : contribution
          ),
        }),
        ...(contributionType === BillingContributionType.PENALTY && {
          penalty: billingFormData!.billingContributions.penalty.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    amount,
                  } as BillingsTaxiTaxiContributionPenalty)
                : contribution
          ),
        }),
      },
    };

    setBillingFormData(formData);
  };

  const onContributionCommentChange = (
    position: number,
    comment: string,
    contributionType: BillingContributionType
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(contributionType === BillingContributionType.BONUS && {
          bonus: billingFormData!.billingContributions.bonus.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    comment,
                  } as BillingsTaxiTaxiContributionBonus)
                : contribution
          ),
        }),
        ...(contributionType === BillingContributionType.PENALTY && {
          penalty: billingFormData!.billingContributions.penalty.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    comment,
                  } as BillingsTaxiTaxiContributionPenalty)
                : contribution
          ),
        }),
      },
    };

    setBillingFormData(formData);
  };

  const onContributionTypeBlur = (
    position: number,
    contributionType: BillingContributionType
  ) => {
    const contributions = billingsTaxiTaxiHelper.getContributionsByType(
      billingFormData!.billingContributions,
      contributionType
    );

    const type = contributions[position].type;

    if (!type) {
      deleteContribution(position, contributionType, false);
    } else {
      validateContributionType(type, position, contributionType);
    }
  };

  const onContributionDistanceBlur = (position: number) => {
    const distance = (
      billingFormData!.billingContributions.bonus[
        position
      ] as BillingsTaxiTaxiContributionBonusFavorableDistance
    ).distance;

    const isDistanceValid = validateContributionDistance(distance, position);

    if (isDistanceValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const onContributionRateBlur = (position: number) => {
    const rate = (
      billingFormData!.billingContributions.bonus[
        position
      ] as BillingsTaxiTaxiContributionBonusFavorableDistance
    ).rate;

    const isRateValid = validateContributionRate(rate, position);

    if (isRateValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const onContributionAmountBlur = (
    position: number,
    contributionType: BillingContributionType
  ) => {
    const contributions = billingsTaxiTaxiHelper.getContributionsByType(
      billingFormData!.billingContributions,
      contributionType
    );

    const amount = (
      contributions[position] as
        | BillingsTaxiTaxiContributionBonusOther
        | BillingsTaxiTaxiContributionPenalty
    ).amount;

    const isAmountValid = validateContributionAmount(
      amount,
      position,
      contributionType
    );

    if (isAmountValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const onContributionCommentBlur = (
    position: number,
    contributionType: BillingContributionType
  ) => {
    const contributions = billingsTaxiTaxiHelper.getContributionsByType(
      billingFormData!.billingContributions,
      contributionType
    );

    const comment = contributions[position].comment;

    const isCommentValid = validateContributionComment(
      comment,
      position,
      contributionType
    );

    if (isCommentValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const validateContributions = () => {
    const contributionsValidation: BillingContributionsValidationResult = {
      bonus: billingFormData!.billingContributions.bonus.map(
        (contribution, index) => {
          switch (contribution.type) {
            case BillingsTaxiTaxiContributionBonusType.BONUS_FAVORABLE_DISTANCE: {
              return {
                position: index,
                type: billingsTaxiTaxiContributionsValidationService.validateType(
                  contribution.type
                ),
                distance:
                  billingsTaxiTaxiContributionsValidationService.validateDistance(
                    contribution.distance
                  ),
                rate: billingsTaxiTaxiContributionsValidationService.validateRate(
                  contribution.rate
                ),
                amount: formValidationService.defaultValidationResult,
                comment:
                  billingsTaxiTaxiContributionsValidationService.validateComment(
                    contribution.comment
                  ),
              };
            }
            case BillingsTaxiTaxiContributionBonusType.BONUS_OTHER: {
              return {
                position: index,
                type: billingsTaxiTaxiContributionsValidationService.validateType(
                  contribution.type
                ),
                distance: formValidationService.defaultValidationResult,
                rate: formValidationService.defaultValidationResult,
                amount:
                  billingsTaxiTaxiContributionsValidationService.validateAmount(
                    contribution.amount
                  ),
                comment:
                  billingsTaxiTaxiContributionsValidationService.validateComment(
                    contribution.comment
                  ),
              };
            }
            default: {
              return {
                position: index,
                type: formValidationService.defaultValidationResult,
                distance: formValidationService.defaultValidationResult,
                rate: formValidationService.defaultValidationResult,
                amount: formValidationService.defaultValidationResult,
                comment: formValidationService.defaultValidationResult,
              };
            }
          }
        }
      ),
      penalty: billingFormData!.billingContributions.penalty.map(
        (contribution, index) => {
          return {
            position: index,
            type: billingsTaxiTaxiContributionsValidationService.validateType(
              contribution.type
            ),
            amount:
              billingsTaxiTaxiContributionsValidationService.validateAmount(
                contribution.amount
              ),
            comment:
              billingsTaxiTaxiContributionsValidationService.validateComment(
                contribution.comment
              ),
          };
        }
      ),
    };

    setContributionsValidationResults(contributionsValidation);

    return (
      contributionsValidation.bonus.every(
        (validation) =>
          validation.amount.isValid &&
          validation.comment.isValid &&
          validation.distance.isValid &&
          validation.rate.isValid &&
          validation.type.isValid
      ) &&
      contributionsValidation.penalty.every(
        (validation) =>
          validation.amount.isValid &&
          validation.comment.isValid &&
          validation.type.isValid
      )
    );
  };

  const deleteContribution = (
    position: number,
    type: BillingContributionType,
    recalculate: boolean = true
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(type === BillingContributionType.BONUS && {
          bonus: billingFormData!.billingContributions.bonus.filter(
            (contribution, index) => index !== position
          ),
        }),
        ...(type === BillingContributionType.PENALTY && {
          penalty: billingFormData!.billingContributions.penalty.filter(
            (contribution, index) => index !== position
          ),
        }),
      },
    };

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(type === BillingContributionType.BONUS && {
        bonus: contributionsValidationResults.bonus
          .filter((validation) => validation.position !== position)
          .map((validation, index) => ({ ...validation, position: index })),
      }),
      ...(type === BillingContributionType.PENALTY && {
        penalty: contributionsValidationResults.penalty
          .filter((validation) => validation.position !== position)
          .map((validation, index) => ({ ...validation, position: index })),
      }),
    };

    setBillingFormData(formData);
    setContributionsValidationResults(validationResults);

    if (recalculate) {
      deleteContributionRecalculateBilling(formData);
    }
  };

  const addNewContribution = () => {
    const newContributionType =
      billingsTaxiTaxiHelper.getContributionTypeFromTabIndex(activeTab);

    if (!newContributionType) {
      return;
    }

    const newContribution =
      billingsTaxiTaxiHelper.getNewContribution(newContributionType);

    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(newContributionType === BillingContributionType.BONUS && {
          bonus: [
            ...billingFormData!.billingContributions.bonus,
            newContribution as BillingsTaxiTaxiContributionBonus,
          ],
        }),
        ...(newContributionType === BillingContributionType.PENALTY && {
          penalty: [
            ...billingFormData!.billingContributions.penalty,
            newContribution as BillingsTaxiTaxiContributionPenalty,
          ],
        }),
      },
    };

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(newContributionType === BillingContributionType.BONUS && {
        bonus: [
          ...contributionsValidationResults.bonus,
          {
            position: contributionsValidationResults.bonus.length,
            type: formValidationService.defaultValidationResult,
            distance: formValidationService.defaultValidationResult,
            rate: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            comment: formValidationService.defaultValidationResult,
          },
        ],
      }),
      ...(newContributionType === BillingContributionType.PENALTY && {
        penalty: [
          ...contributionsValidationResults.penalty,
          {
            position: contributionsValidationResults.penalty.length,
            type: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            comment: formValidationService.defaultValidationResult,
          },
        ],
      }),
    };

    setBillingFormData(formData);
    setContributionsValidationResults(validationResults);
  };

  const tabsData: TabsData = useMemo(() => {
    if (!billingFormData) {
      return [];
    }

    return billingDataFactory.createTabsData(
      billingFormData.billingContributions,
      isBillingDataLoading,
      deleteContribution,
      contributionsValidationResults,
      onContributionTypeChange,
      onContributionDistanceChange,
      onContributionRateChange,
      onContributionAmountChange,
      onContributionCommentChange,
      onContributionTypeBlur,
      onContributionDistanceBlur,
      onContributionRateBlur,
      onContributionAmountBlur,
      onContributionCommentBlur
    );
  }, [billingFormData, billingSummaryData, contributionsValidationResults, selectedAppLanguage]);

  const tabMaxContributions = useMemo(
    () => billingsTaxiTaxiHelper.getMaxContributionsFromTabIndex(activeTab),
    [activeTab]
  );

  const onOrderHistoryButtonClick = () => {
    if (shouldShowOrderHistory) {
      setShouldShowOrderHistory(false);
      return;
    }

    setShouldShowOrderHistory(true);
  };

  const billingsTaxiTaxiToolsProps = {
    shouldShowOrderHistory,
    onOrderHistoryButtonClick,
  };

  return (
    <>
      <div className="billings">
        <HeadingComponent
          title={translations.header.headingText.replace("#{routeId}", routeId)}
        />
        <Row>
          <Column lg={8}>
            <Row>
              <Column withPaddings>
                <div className="billings_map_wrapper">
                  <MapComponent
                    markers={mapMarkers}
                    autoFit
                    autoFitOnUpdate
                    routes={mapRoutes}
                  />
                  <BillingsToolsComponent {...billingsTaxiTaxiToolsProps} />
                </div>
              </Column>
              <Column withPaddings>
                <ContractDetailsComponent
                  isLoading={isBillingDataLoading}
                  contractDetails={billingSummaryData?.contractDetails ?? null}
                />
              </Column>
              <Column withPaddings>
                <Row>
                  <Column lg={12}>
                    <BillingsRouteDetailsFormComponent
                      billingNodes={billingFormData?.billingNodes}
                      formValidationResults={
                        formValidationResults.nodeValidations
                      }
                      onBillingNodeChanged={onBillingNodeChanged}
                      onInputBlur={onInputBlur}
                      onCopyFromPlannedDistance={onCopyFromPlannedDistance}
                      validationOnBlur={validationOnBlur}
                    />
                  </Column>
                </Row>
              </Column>
              <Column withPaddings>
                <BillingRouteDetailsComponent billingSummaryData={billingSummaryData} />
              </Column>
              {!!billingData && (
                <Column withPaddings>
                  <TabsComponent
                    data={tabsData}
                    onActiveTabChange={setActiveTab}
                  />
                  <div className="billings_contributions_add_button">
                    <ButtonComponent
                      onClick={addNewContribution}
                      type="success"
                      title={translations.contributionAddButtonTitle}
                      isDisabled={
                        tabsData[activeTab].totalTableRows >=
                        tabMaxContributions
                      }
                    >
                      {translations.contributionAddButtonLabel}
                    </ButtonComponent>
                  </div>
                </Column>
              )}
              <Column withPaddings>
                <CardComponent
                  classNames={{ root: "billing_details" }}
                  header={{ title: translations.billingSummary.summaryLabel }}
                >
                  <BillingDetailsComponent billingData={billingData} billingSummaryData={billingSummaryData}/>
                </CardComponent>
              </Column>
              {!!isSettleButtonVisible && (
                <Column>
                  <ButtonComponent type="primary" onClick={openModal}>
                    {translations.submitLabel}
                  </ButtonComponent>
                </Column>
              )}
              <Column withPaddings>
                <RelatedBillingsCargoTaxiComponent
                  forwardingId={billingSummaryData?.forwardingId!}
                />
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiDriverComponent
                  planEntryId={billingSummaryData?.planEntryId}
                  forwardingId={billingSummaryData?.forwardingId!}
                />
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiTaxiComponent
                  forwardingId={billingSummaryData?.forwardingId!}
                />
              </Column>
            </Row>
          </Column>
          <Column lg={4} withPaddings>
            <CardComponent classNames={{ root: "billings_history_messenger_container", content: "h-100" }}>
              {!!billingSummaryData &&
                (shouldShowOrderHistory ? (
                  <OrderDetailsHistoryComponent
                    orderUuid={billingSummaryData.orderId}
                    refetchFlag={false}
                  />
                ) : (
                  <MessengerComponent
                    billingType={MessengerBillingType.TAXI}
                    channelsAvailability={messengerChannelsAvailability}
                    orderUuid={billingSummaryData.orderId}
                    billingUuid={billingUuid}
                    planEntryUuid={billingSummaryData.planEntryId}
                  />
                ))}
            </CardComponent>
          </Column>
        </Row>
        <BillingsSummaryConfirmationComponent
          isVisible={isSummaryConfirmationVisible}
          onSubmit={onBillingDataSave}
          onClose={onModalClose}
          billingData={billingData!}
          billingSummaryData={billingSummaryData!}
          isLoading={isBillingSavePending}
        />
      </div>
    </>
  );
};

export default BillingsTaxiTaxiEditComponent;
